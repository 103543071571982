// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/assignment_types/posting/phone.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/assignment_types/posting/inperson.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.stand-by__title[data-v-501bb3b5] {\n  margin-bottom: 10px;\n  font-weight: normal;\n}\n.stand-by__fields-wrapper[data-v-501bb3b5] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: calc(100% + 15px);\n  margin: 15px -7.5px 0;\n}\n.stand-by__field[data-v-501bb3b5] {\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  -ms-flex-preferred-size: 50%;\n      flex-basis: 50%;\n  margin: 0 7.5px;\n}\n.stand-by__btn[data-v-501bb3b5] {\n  width: 100%;\n  margin-top: 15px;\n  padding: 0 20px;\n}\n.stand-by__link-wrapper[data-v-501bb3b5] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  height: 35px;\n  border-bottom: 1px solid #e9ebef;\n}\n.stand-by__link[data-v-501bb3b5] {\n  width: 100%;\n  text-align: left;\n  text-decoration: none;\n}\n.stand-by__event[data-v-501bb3b5]:first-child {\n  border-top: 1px solid #e9ebef;\n}\n.stand-by__events[data-v-501bb3b5] {\n  margin-top: 15px;\n}\n.stand-by__event[data-v-501bb3b5] {\n  -ms-flex-wrap: nowrap;\n      flex-wrap: nowrap;\n  padding: 10px 0;\n  border-bottom: 1px solid #e9ebef;\n}\n.stand-by__event-text[data-v-501bb3b5] {\n  font-size: 12px;\n}\n.stand-by__event-icon[data-v-501bb3b5] {\n  display: block;\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  border-radius: 50%;\n  background-color: #fff;\n  background-position: 50% 50%;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.stand-by__event-icon--remote[data-v-501bb3b5] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-assignment-type-phone-icon);\n}\n.stand-by__event-icon--on_site[data-v-501bb3b5] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-image: var(--image-assignment-type-in_person-icon);\n}\n", ""]);
// Exports
module.exports = exports;
